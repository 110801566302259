import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
    protected faqData: any;

    constructor(protected translate: TranslateService) {
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.faqData = [
            {
                id: 1
            },
            {
                id: 2
            },
            {
                id: 3
            },
            {
                id: 4
            },
            {
                id: 5
            },
            {
                id: 6
            },
            {
                id: 7
            },
            {
                id: 8
            },
            {
                id: 9
            },
            {
                id: 10
            },
            {
                id: 11
            },

            {
                id: 12
            },
        ];
    }

    translatedQuestion(question) {
/*        if (this.translate.instant('FAQ_Q' + question).includes('à table!')) {
            this.translate.instant('FAQ_Q' + question).replace('à table!', ;
        }*/
        return this.translate.instant('FAQ_Q' + question);
    }

    translatedResponse(response) {
        if (response === 3) {
            return this.translate.instant('FAQ_R3#1') + '\n' +
                this.translate.instant('FAQ_R3#2') + '\n' +
                this.translate.instant('FAQ_R3#3') + '\n' +
                this.translate.instant('FAQ_R3#4') + '\n' +
                this.translate.instant('FAQ_R3#5') + '\n' +
                this.translate.instant('FAQ_R3#6') + '\n';
        } else {
            return this.translate.instant('FAQ_R' + response);
        }
    }
}
