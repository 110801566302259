import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {EthService} from '../../ethereum/eth.service';
import {Router} from '@angular/router';
import {subscriptionLogsToBeFn} from 'rxjs/internal/testing/TestScheduler';

@Component({
    selector: 'app-get-started',
    templateUrl: './how-to-start.component.html',
    styleUrls: ['./how-to-start.component.css']
})
export class HowToStartComponent implements OnInit, OnDestroy {

    protected address: string;
    protected ethLogo: string;
    protected bunny: string;
    protected metaMaskLogo: string;
    protected alive = true;
    protected balance: any;
    protected casatookan: string;
    protected casatookanView1: string;
    protected casatookanView2: string;
    protected casatookanView3: string;
    protected pickYourYummy: string;
    protected bunnyHappy: string;
    protected unicornPink: string;
    protected arrowDown: string;
    protected arrowUp: string;

    protected downCT: boolean;
    protected downMM: boolean;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.ethLogo = './assets/images/ETH.svg';
        this.metaMaskLogo = './assets/images/metamask.svg';
        this.bunny = './assets/images/Lapin.svg';
        this.casatookan = './assets/images/casa-tookan-logo.png';
        this.casatookanView1 = './assets/images/casaTookan1.png';
        this.casatookanView2 = './assets/images/casaTookan2.png';
        this.casatookanView3 = './assets/images/casaTookan3.png';
        this.pickYourYummy = './assets/images/menu/Menu-Pick.svg';
        this.bunnyHappy = './assets/images/bunny_happy.png';
        this.unicornPink = './assets/images/unicorn_pink.png';
        this.arrowDown = './assets/images/arrowDown.png';
        this.arrowUp = './assets/images/arrowUp.png';
        this.downCT = true;
        this.downMM = true;
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    openPickYourYummy() {
        this.router.navigateByUrl('/pickYourYummy');
    }

    rotateCT() {
        this.downCT = !this.downCT;
    }

    rotateMM() {
        this.downMM = !this.downMM;
    }

}
