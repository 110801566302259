import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HomeComponent} from '../pages/home/home.component';
import {MarketplaceComponent} from '../pages/marketplace/marketplace.component';
import {MonsterDetailComponent} from '../pages/monster-detail/monster-detail.component';
import {HowToStartComponent} from '../pages/how-to-start/how-to-start.component';
import {GameComponent} from '../pages/game/game.component';
import {FaqComponent} from '../pages/faq/faq.component';
import {MyYummiesComponent} from '../pages/my-yummies/my-yummies.component';
import {MyRecipesComponent} from '../pages/my-recipes/my-recipes.component';
import {PickYourYummyComponent} from '../pages/pick-your-yummy/pick-your-yummy.component';
import {MyYummyDetailComponent} from '../pages/my-yummy-detail/my-yummy-detail.component';
import {SellMyYummyComponent} from '../pages/sell-my-yummy/sell-my-yummy.component';
import {MyYummiesHatchingComponent} from '../pages/my-yummies-hatching/my-yummies-hatching.component';
import {TranslateService} from '@ngx-translate/core';
import {HowToYummiesComponent} from '../pages/howtoyummies/howtoyummies.component';

// List of the routes and their component, routes with label are for the nav bar

export const appRoutes = [
    {path: '', component: HomeComponent},
    {path: 'getstarted', component: HowToStartComponent, label: 'How to start', icon: './assets/images/menu/Menu-How.svg', v2: true},
    {
        path: 'pickYourYummy',
        component: PickYourYummyComponent,
        label: 'Pick Your Yummy',
        icon: './assets/images/menu/Menu-Pick.svg',
        v2: true
    },
    {
        path: 'howtoyummies',
        component: HowToYummiesComponent,
    },
    {path: 'marketplace', component: MarketplaceComponent, label: 'Marketplace', icon: './assets/images/menu/Menu-Market.svg', v1: false},
    {path: 'game', component: GameComponent, label: 'Game', icon: './assets/images/menu/Menu-Game.svg', v2: true},
    {path: 'myyummies', component: MyYummiesComponent, label: 'My Yummies', icon: './assets/images/menu/Menu-Yummies.svg', v2: false},
    // my Yummies hathching is for v2 where yummies arent ready yet, need to change to my yummies when marketplace is ready
    {
        path: 'myyummieshatching',
        component: MyYummiesHatchingComponent,
        label: 'My Yummies',
        icon: './assets/images/menu/Menu-Yummies.svg',
        v2: true
    },
    {path: 'myrecipes', component: MyRecipesComponent, label: 'My Recipes', icon: './assets/images/menu/Menu-Recipes.svg', v1: false},
    {path: 'faq', component: FaqComponent},
    {path: 'monster/:id', component: MonsterDetailComponent},
    {path: 'myYummy/:id', component: MyYummyDetailComponent},
    {path: 'sellMyYummy/:id', component: SellMyYummyComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
