import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import {MonsterService} from '../../../service/monsterService';

@Component({
    selector: 'app-monster-detail',
    templateUrl: './monster-detail.component.html',
    styleUrls: ['./monster-detail.component.css']
})
export class MonsterDetailComponent implements OnInit {
    protected yummySelected;
    private subscription;
    protected unicornSrc: string;
    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.unicornSrc = './assets/images/Licorne.svg';
        this.subscription = this.route.params.subscribe((params) => {
/*                this.monsterService.getMonster(params.id).subscribe(
                    response => this.yummySelected = response,
                    error => console.log('cannot get yummySelected with id ' + params.id));*/
            console.log(this.yummySelected);
        });
    }

}
