import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
// import {MonsterService} from '../../../service/monsterService';

@Component({
  selector: 'app-sell-my-yummy',
  templateUrl: './sell-my-yummy.component.html',
  styleUrls: ['./sell-my-yummy.component.css']
})
export class SellMyYummyComponent implements OnInit {
    protected myYummy;
    private subscription;
    protected unicornSrc: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
      this.unicornSrc = './assets/images/Licorne.svg';
      /*this.subscription = this.route.params.subscribe((params) => {
          this.monsterService.getMonster(params.id).subscribe(
              response => this.myYummy = response,
              error => console.log('cannot get yummySelected with id ' + params.id));
          console.log(this.myYummy);
      });*/
  }

}
