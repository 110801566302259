import { Component, OnInit } from '@angular/core';
// import {MonsterService} from '../../../service/monsterService';
import {Yummy} from '../../../service/model/monsterModel';
import { Router } from '@angular/router';
import {EthService} from '../../ethereum/eth.service';

@Component({
    selector: 'app-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent implements OnInit {
    protected monsters: Yummy[];
    protected yummies: any[];
    protected bunnySrc: string;
    protected unicornSrc: string;
    protected salamanderSrc: string;

    constructor(public router: Router, public ethService: EthService) { }

    ngOnInit() {
        // this.getMonsters();
        // this.getYummies();
        this.bunnySrc = './assets/images/Lapin.svg';
        this.unicornSrc = './assets/images/Licorne.svg';
        this.salamanderSrc = './assets/images/Salamandre.svg';
    }

 /*   public getMonsters() {
        this.monstersService.getMonsters().subscribe(
            response => this.monsters = response,
            error => console.log('error ' + error));
    }
*/
  /*  protected getYummies() {
        this.monstersService.getYummies().subscribe(
            response => this.yummies = response['results'],
            error => console.log('error on getyummies' + error));
    }*/
    protected goToDetail(monsterId) {
        this.router.navigateByUrl('/monster/' + monsterId).then((result) => console.log(result));
    }
}
