import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {
    protected soupeImageHeader: String;
    protected bunnySrc: String;
    protected unicornSrc: String;
    protected salamanderSrc: String;
    protected cookie: String;
    protected pattesOne: String;
    protected pattesTwo: String;
    protected pattesThree: String;
    protected boat: string;

    constructor(protected translate: TranslateService) {
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.soupeImageHeader = './assets/images/Final_Soup.jpg';
        this.bunnySrc = './assets/images/lap_base_color.png';
        this.unicornSrc = './assets/images/lic_base_color.png';
        this.salamanderSrc = './assets/images/sal_base_color.png';
        this.cookie = './assets/images/Final_Yummy Cookie.png';
        this.pattesOne = './assets/images/GamePagePattes1.png';
        this.pattesTwo = './assets/images/GamePagePattes2.png';
        this.pattesThree = './assets/images/GamePagePattes3.png';
        this.boat = './assets/images/boat.png';
    }

    protected translateLabel(label) {
        return this.translate.instant(label);
    }
}
