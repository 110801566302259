import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EthService} from '../../ethereum/eth.service';

@Component({
    selector: 'app-my-yummies-hatching',
    templateUrl: './my-yummies-hatching.component.html',
    styleUrls: ['./my-yummies-hatching.component.css']
})
export class MyYummiesHatchingComponent implements OnInit {
    protected mixUnicorn: string;
    protected mixBunny: string;
    protected mixSalamander: string;

    protected mixingUnicorn: string;
    protected mixingBunny: string;
    protected mixingSalamander: string;

    // In smart contract unicornID = 0, BunnyId = 1, SalamanderId = 2
    protected numberOfUnicornOwned: number;
    protected numberOfBunnyOwned: number;
    protected numberOfSalamanderOwned: number;


    constructor(protected eth: EthService, protected router: Router) {
    }

    ngOnInit() {
        this.mixUnicorn = './assets/images/mixUnicorn.png';
        this.mixBunny = './assets/images/mixBunny.png';
        this.mixSalamander = './assets/images/mixSalamander.png';
        this.mixingUnicorn = './assets/images/mixingUnicorn.png';
        this.mixingBunny = './assets/images/mixingBunny.png';
        this.mixingSalamander = './assets/images/mixingSalamander.png';
        if (this.eth.hasBrowserMetaMask()) {
            this.eth.getAccounts().then(() => {
                if (this.eth.accountsList.length > 0) {
                    this.getNumberOfOwnedYummies(this.eth.accountsList[0]).then();
                } else {
                    this.eth.getAccounts().then((res) => {
                        if (res) {
                            this.getNumberOfOwnedYummies(this.eth.accountsList[0]).then(() => {
                            /*    this.router.navigateByUrl('/').then(() => {
                                    this.router.navigateByUrl('myyummieshatching').then(() => {
                                    });
                                });*/
                            });
                        }
                    });
                }
            });
        } else {
            console.log('please install Metamask and refresh this page');
        }
    }

    protected async getNumberOfOwnedYummies(account) {
        this.numberOfUnicornOwned = await this.eth.getNumberOfOwnedYummiesByAccountAndYummyId(account, this.eth.UNICORN_ID);
        this.numberOfBunnyOwned = await this.eth.getNumberOfOwnedYummiesByAccountAndYummyId(account, this.eth.BUNNY_ID);
        this.numberOfSalamanderOwned = await this.eth.getNumberOfOwnedYummiesByAccountAndYummyId(account, this.eth.SALAMANDER_ID);
    }

    goToPickYourYummy() {
        this.router.navigateByUrl('/pickYourYummy');
    }
}
