import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// import {MonsterService} from '../../../service/monsterService';

@Component({
  selector: 'app-my-yummy-detail',
  templateUrl: './my-yummy-detail.component.html',
  styleUrls: ['./my-yummy-detail.component.css']
})
export class MyYummyDetailComponent implements OnInit {
    protected myYummy;
    private subscription;
    protected unicornSrc: string;
  constructor(private route: ActivatedRoute, public router: Router) { }
  ngOnInit() {
      this.unicornSrc = './assets/images/Licorne.svg';
      this.subscription = this.route.params.subscribe((params) => {
          console.log(params);
          // this.monsterService.getMonster(params.id).subscribe(
          //     response => this.myYummy = response,
          //     error => console.log('cannot get yummySelected with id ' + params.id));
          // console.log(this.myYummy);
      });
  }
    protected goToSellMyYummy(monsterId) {
        this.router.navigateByUrl('/sellMyYummy/' + monsterId).then((result) => console.log(result));
    }

}
