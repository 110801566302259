import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {appRoutes} from './app-routing/app-routing.module';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {EthService} from './ethereum/eth.service';
import {WEB3} from './ethereum/tokens';
import Web3 from 'web3';
import {Location} from '@angular/common';
import {Gtag} from 'angular-gtag';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    protected routes: any;
    protected logoNav: string;
    protected logoMenu: string;
    protected edsLogo: string;
    protected bcyLogo: string;
    protected fb_logo: string;
    protected tw_logo: string;
    protected tl_logo: string;
    protected spoon_logo: string;
    protected label: string;
    protected bcyLogoSmall: string;
    protected edsLogoSmall: string;
    protected countDownDate: number;
    protected countDownInfo: string;
    protected menuPickYourYummy: string;
    protected days: number;
    protected hours: number;
    protected minutes: number;
    public networkType: string;
    protected discord_logo: string;


    constructor(private location: Location, protected translate: TranslateService, protected router: Router, @Inject(DOCUMENT) document,
                protected eth: EthService, protected gtag: Gtag,) {
        this.translate.getBrowserLang() === 'ja' ? this.translate.setDefaultLang('ja') : this.translate.setDefaultLang('en');
        this.router.events.subscribe(event => {
            gtag.pageview();
        });

    }

    ngOnInit(): void {
        this.routes = appRoutes.filter((route) => route.v2);
        this.countDownMaj();
        this.logoNav = './assets/images/logoNav.png';
        this.logoMenu = './assets/images/menu/LogoMenu.svg';
        this.spoon_logo = './assets/images/spoon-logo.png';
        this.edsLogo = './assets/images/Logo-EDS.png';
        this.bcyLogo = './assets/images/Logo-BCY.png';
        this.fb_logo = './assets/images/flogo_RGB_HEX-144.svg ';
        this.tw_logo = './assets/images/Twitter_Social_Icon_Rounded_Square_Color.svg ';
        this.discord_logo = './assets/images/discord.svg ';
        this.tl_logo = './assets/images/telegram-seeklogo.com.svg';
        this.bcyLogoSmall = './assets/images/BCYLogoSmall.png';
        this.edsLogoSmall = './assets/images/EDSLogoSmall.png';
        this.menuPickYourYummy = this.translate.instant('ICON_PICKYOURYUMMY');
        if (this.eth.hasBrowserMetaMask()) {
            this.eth.getNetworkType().then((res) => {
                this.networkType = res;
                if (res === 'robsten') {
                    console.log('Please switch to mainnetwork');
                }
            });
            this.eth.enableMetamask();
            this.eth.accountChangeListener();
        } else {
            console.log('no metamask enabled');
        }
    }

    hideCollapse() {
        const menuButton = document.getElementById('#menuButton');
        const menuBar = document.getElementById('navbarTogglerMenu');
        if (menuBar.classList.contains('show')) {
            menuButton.click();
        }
    }

    public tranlatedLabel(label) {
        switch (label) {
            case 'Pick Your Yummy':
                return this.translate.getDefaultLang() === 'ja'
                    ? this.translate.instant('ICON_PICKYOURYUMMY') + '<br>' + this.translate.instant('ICON_PICKYOURYUMMY#2')
                    : this.translate.instant('ICON_PICKYOURYUMMY');
            case 'How to start':
                return this.translate.instant('ICON_HOWTOSTART');
            case 'Game':
                return this.translate.instant('ICON_GAME');
            case 'My Yummies':
                return this.translate.instant('ICON_MYYUMMIES');
        }
    }

    countDownMaj() {
        this.countDownDate = new Date('Mar 5, 2019 00:00:00').getTime();
        // Update the count down every 1 second
        const x = setInterval(() => {
            // Get todays date and time
            const now = new Date().getTime();
            // Find the distance between now and the count down date
            const distance = this.countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // Display the result in the element with id="demo"
            this.countDownInfo = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                this.countDownInfo = '';
            }
        }, 1000);
    }

    onClickUKFlag() {
        this.translate.setDefaultLang('en');
        this.hideCollapse();
    }

    onClickjapanFlag() {
        this.translate.setDefaultLang('ja');
        this.hideCollapse();
    }

}
