import {Component, Input, Output} from '@angular/core';

@Component({
    selector: 'app-yummy-card',
    template: `
        <div class="card mb-3">
            <div class="card-body" (click)="openCard(yummy.id)">
                <h5 class="card-title"> {{yummy.name}}</h5>
            </div>
            <div class="card-footer">
                <!--<button class="btn btn-primary"(click)="buyYummy(yummy.id)">Buy me</button>-->
            </div>
        </div>
    `
})

export class YummyCardComponent {
    @Input() yummy;
    @Input() router;
    @Input() ethService;

    @Output()
    protected openCard(id) {
        this.router.navigateByUrl('/monster/' + id);
    }

    /*  @Output() protected buyYummy(species: number) {
          this.ethService.buyNewYummy(species).then((result) => {
              console.log(result);
          });
      }*/
}
