import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    protected bunnySrc: string;
    protected unicornSrc: string;
    protected salamanderSrc: string;
    protected aTableImage: string;
    protected logoFull: string;
    protected headerImage: string;
    protected headerCutIphone: string;
    protected pickYourYummy: string;
    protected cookie: string;
    protected bol: string;
    protected yummies: string;
    protected pattes1: string;
    protected pattes2: string;
    protected pattes3: string;
    protected pattes4: string;

    constructor(protected translate: TranslateService, protected router: Router) {
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.bunnySrc = './assets/images/Lapin.svg';
        this.unicornSrc = './assets/images/Licorne.svg';
        this.salamanderSrc = './assets/images/Salamandre.svg';
        this.aTableImage = './assets/images/PromChara.svg';
        this.logoFull = './assets/images/LOGO Atable Final.png';
        this.cookie = './assets/images/Final_Eaten Cookie.png';
        this.pickYourYummy = './assets/images/Final_Market.png';
        this.headerImage = './assets/images/HeaderCut.jpg';
        this.yummies = './assets/images/Final_3 Yummies.png';
        this.bol = './assets/images/Final_Bol.png';
        this.pattes1 = './assets/images/HomePagePattes1.png';
        this.pattes2 = './assets/images/HomePagePattes2.png';
        this.pattes3 = './assets/images/HomePagePattes3.png';
        this.pattes4 = './assets/images/HomePagePattes4.png';
        this.headerCutIphone = './assets/images/HeaderCutiPhone.jpg';
    }

    protected openMarketPlace() {
        this.router.navigateByUrl('/marketplace');
    }

    openPickYourYummy() {
        this.router.navigateByUrl('/pickYourYummy');
    }

    protected translateLabel(label) {
        return this.translate.instant(label);
    }
}
