import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-howtoyummies',
    templateUrl: './howtoyummies.component.html',
    styleUrls: ['./howtoyummies.component.css']
})
export class HowToYummiesComponent implements OnInit {
    protected linkPdf;

    constructor(protected route: ActivatedRoute, protected translate: TranslateService) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            console.log(params.pdf);
        });
        this.linkPdf = this.translate.getDefaultLang() === 'ja'
            ? {
                url: './assets/pdf/a_table_presale_tutorial_jp.pdf',
            } : {
                url: './assets/pdf/a_table_presale_tutorial.pdf',
            };
    }

}
