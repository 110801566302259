import {Component, OnInit} from '@angular/core';
import {EthService} from '../../ethereum/eth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Gtag} from 'angular-gtag';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-pick-your-yummy',
    templateUrl: './pick-your-yummy.component.html',
    styleUrls: ['./pick-your-yummy.component.css']
})
export class PickYourYummyComponent implements OnInit {
    protected bunnyPrice: string;
    protected unicornPrice: string;
    protected salamanderPrice: string;

    protected mixBunny: string;
    protected mixUnicorn: string;
    protected mixSalamander: string;

    protected ethBalance: any;
    protected currentWallet: string;
    protected notConnectedMessage: string;
    protected message: string;
    protected hasMetamask: boolean;

    protected yummyTypeToBuy: number;
    protected nameOfYummyType: string;
    protected amountSent: string;
    protected isSwiss: string;
    protected actualYummyPrice: string;

    protected linkToPdfHowTo: any;

    protected buyingYummyForm: FormGroup;
    protected etherscanAddress: string;
    protected txHash: string;
    protected fillForm: string;


    constructor(protected eth: EthService, protected router: Router, protected form: FormBuilder,
                protected gtag: Gtag, protected translate: TranslateService) {
    }

    ngOnInit() {
        this.mixUnicorn = './assets/images/mixUnicorn.png';
        this.mixBunny = './assets/images/mixBunny.png';
        this.mixSalamander = './assets/images/mixSalamander.png';
        if (this.eth.hasBrowserMetaMask()) {
            this.eth.getAccounts().then(() => {
                if (this.eth.accountsList.length > 0) {
                    this.currentWallet = this.eth.accountsList[0];
                } else {
                    this.eth.getAccounts().then((res) => {
                        if (res) this.currentWallet = res[0];
                    });
                }
            });
            this.eth.tx.subscribe((hash) => {
                if (hash) {
                    this.txHash = hash;
                    this.etherscanAddress = environment.etherscanLink + hash;
                    this.openTxModal();
                }
            });
            this.unicornPrice = '';
            this.bunnyPrice = '';
            this.salamanderPrice = '';
            this.buyingYummyForm = this.form.group({
                isSwiss: [this.isSwiss, Validators.required],
                price: ['', Validators.required]
            });
        } else {
            console.log('please install Metamask and refresh this page! ');
        }
    }

    protected async yummyPrice() {
        this.unicornPrice = await this.eth.getYummyPrice(this.eth.UNICORN_ID);
        this.bunnyPrice = await this.eth.getYummyPrice(this.eth.BUNNY_ID);
        this.salamanderPrice = await this.eth.getYummyPrice(this.eth.SALAMANDER_ID);
    }

    protected buyUnicorn() {
        return this.yummyPrice().then(() => {
            return this.eth.buyYummy(this.currentWallet, this.eth.UNICORN_ID, this.amountSent, this.buyingYummyForm.get('isSwiss').value)
                .then((res) => console.log('JSON of transaction: ', res));
        });
    }

    protected buyBunny() {
        return this.yummyPrice().then(() => {
            return this.eth.buyYummy(this.currentWallet, this.eth.BUNNY_ID, this.amountSent, this.buyingYummyForm.get('isSwiss').value)
                .then((res) => console.log('JSON of transaction: ', res));
        });

    }

    protected buySalamander() {
        return this.yummyPrice().then(() => {
            return this.eth.buyYummy(this.currentWallet, this.eth.SALAMANDER_ID, this.amountSent, this.buyingYummyForm.get('isSwiss').value)
                .then((res) => console.log('JSON of transaction: ', res));
        });
    }

    protected setYummyTypeToBuy(type) {
        this.yummyTypeToBuy = type;
        switch (this.yummyTypeToBuy) {
            case this.eth.UNICORN_ID :
                this.yummyPrice().then(() => {
                    this.nameOfYummyType = 'Unicorn';
                    this.amountSent = this.unicornPrice;
                    this.actualYummyPrice = this.unicornPrice;
                });
                break;
            case this.eth.BUNNY_ID :
                this.yummyPrice().then((res) => {
                    this.nameOfYummyType = 'Bunny';
                    this.amountSent = this.bunnyPrice;
                    this.actualYummyPrice = this.bunnyPrice;
                });
                break;
            case this.eth.SALAMANDER_ID :
                this.yummyPrice().then(() => {
                    this.nameOfYummyType = 'Salamander';
                    this.amountSent = this.salamanderPrice;
                    this.actualYummyPrice = this.salamanderPrice;
                });
                break;
            default :
                return 'INVALID OBJECT';
        }
    }

    protected translateLabel(label) {
        return this.translate.instant(label);
    }

    protected buyYummy() {
        if (this.buyingYummyForm.invalid) {
            this.fillForm = 'Please inform us if you are buying from Switzerland';
        } else {
            this.fillForm = '';
            switch (this.yummyTypeToBuy) {
                case this.eth.UNICORN_ID :
                    document.getElementById('closeModal').click();
                    return this.buyUnicorn();
                case this.eth.BUNNY_ID :
                    document.getElementById('closeModal').click();
                    return this.buyBunny();
                case this.eth.SALAMANDER_ID :
                    document.getElementById('closeModal').click();
                    return this.buySalamander();
                default :
                    console.log('No valid Id entered');
            }
        }
    }

    protected getPdfHowTo() {
        console.log(this.translate.getDefaultLang());
        this.linkToPdfHowTo = this.translate.getDefaultLang() === 'ja'
            ? './assets/pdf/a_table_presale_tutorial_jp.pdf' : './assets/pdf/a_table_presale_tutorial.pdf';
        this.router.navigateByUrl('/howtoyummies', {queryParams: {pdf: this.linkToPdfHowTo}});
    }

    protected openTxModal() {
        document.getElementById('openModalButton').click();
    }

    protected closeTxModal() {
        this.etherscanAddress = '';
    }
}
