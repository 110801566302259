import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-my-yummies',
    templateUrl: './my-yummies.component.html',
    styleUrls: ['./my-yummies.component.css']
})
export class MyYummiesComponent implements OnInit {
    protected unicornSrc;
    constructor(public router: Router) { }

    ngOnInit() {
        this.unicornSrc = './assets/images/Licorne.svg';
    }

    protected goToDetail(monsterId) {
        this.router.navigateByUrl('/myYummy/' + monsterId).then((result) => console.log(result));
    }
}
