import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {MarketplaceComponent} from './pages/marketplace/marketplace.component';
import {HomeComponent} from './pages/home/home.component';

import {AppRoutingModule} from './app-routing/app-routing.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// import {MonsterService} from '../service/monsterService';
import {MonsterDetailComponent} from './pages/monster-detail/monster-detail.component';
import {HowToStartComponent} from './pages/how-to-start/how-to-start.component';
import {GameComponent} from './pages/game/game.component';
import {FaqComponent} from './pages/faq/faq.component';
import {EthereumModule} from './ethereum/ethereum.module';
import {EthService} from './ethereum/eth.service';
import {MyRecipesComponent} from './pages/my-recipes/my-recipes.component';
import {MyYummiesComponent} from './pages/my-yummies/my-yummies.component';
import {APP_BASE_HREF} from '@angular/common';
import {YummyCardComponent} from './components/yummyCard.component';
import {PickYourYummyComponent} from './pages/pick-your-yummy/pick-your-yummy.component';
import {MyYummyDetailComponent} from './pages/my-yummy-detail/my-yummy-detail.component';
import {SellMyYummyComponent} from './pages/sell-my-yummy/sell-my-yummy.component';
import {MyYummiesHatchingComponent} from './pages/my-yummies-hatching/my-yummies-hatching.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GtagModule} from 'angular-gtag';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { HowToYummiesComponent } from './pages/howtoyummies/howtoyummies.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MarketplaceComponent,
        HomeComponent,
        MonsterDetailComponent,
        HowToStartComponent,
        GameComponent,
        FaqComponent,
        MyRecipesComponent,
        MyYummiesComponent,
        YummyCardComponent,
        PickYourYummyComponent,
        MyYummyDetailComponent,
        SellMyYummyComponent,
        MyYummiesHatchingComponent,
        HowToYummiesComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        NgbModule.forRoot(),
        AppRoutingModule,
        EthereumModule,
        FormsModule,
        PdfViewerModule,
        ReactiveFormsModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
        GtagModule.forRoot({
                trackingId: 'UA-123146504-1',
                trackPageviews: true
            }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    deps: [HttpClient]
                }
            }),

    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        EthService, {provide: APP_BASE_HREF, useValue: '/'}, Title
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
