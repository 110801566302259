import {Inject, Injectable, NgZone} from '@angular/core';
import {WEB3} from './tokens';
import Web3 from 'web3';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Gtag} from 'angular-gtag';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';

const tokenAbi = require('./tokenContract.json');
const presaleTokenAbi = require('./presaleTokenContract.json');

@Injectable()
export class EthService {
    // private _presaleTokenRobstenContractAddress = '0xd2225e47c943ad46f38ae031fb4943c2a34284a4';
    private _presaleTokenMainNetworkContractAddress = '0x5d77b850ba7c6218dbb4ff087ad25dee0baa638f';
    private _tokenContract: any;
    public accountsList: string[] = [];
    public currentAccountAddress: string;
    public accountsAvailable: boolean;

    public UNICORN_ID = 0;
    public SALAMANDER_ID = 1;
    public BUNNY_ID = 2;
    // hackworkaround
    private redirectCount: number;
    public transactionHash: string;
    public networkType: string;
    public isMainNetworkType: boolean;
    public etherScanLink: string;

    protected transactionAddress = new BehaviorSubject<any>(false);
    public tx = this.transactionAddress.asObservable();

    constructor(@Inject(WEB3) protected web3: Web3, private zone: NgZone, protected router: Router,
                protected location: Location, protected gtag: Gtag) {
        // get the contractfrom the evm
        this.redirectCount = 0;
        this.transactionHash = '';
        this.gtag.config({
            'custom_map': {
                'dimension1': 'is_swiss',
                'dimension2': 'transaction_hash'
            }
        });
        this.etherScanLink = '';

        if (this.hasBrowserMetaMask()) {
            this._tokenContract = new this.web3.eth.Contract(presaleTokenAbi, environment.redeemContractAddress);
            this.getNetworkType().then((res) => {
                if (this.networkType !== 'main') {
                    this.isMainNetworkType = false;
                } else {
                    this.isMainNetworkType = true;
                }
            });
            this.getAccounts().then(() => {
            });
        }
    }

    public islogout() {
        this.web3.eth.getAccounts()
            .then((accounts) => {
                return !(accounts.length > 0);
            })
            .catch((err) => {
                return true;
            });
    }

    public getNetworkType() {
        return this.web3.eth.net.getNetworkType().then((res) => {
            this.networkType = res;
            return res;
        });
    }

    public async getAccounts() {
        this.accountsList = await this.web3.eth.getAccounts();
        if (this.accountsList.length === 0) {
            this.accountsAvailable = false;
        } else {
            this.accountsAvailable = true;
            this.currentAccountAddress = this.accountsList[0];
        }
    }

    public accountChangeListener() {
        this.web3.currentProvider.publicConfigStore.on('update', (update) => {
            const previousAccountAddress = this.currentAccountAddress;
            const previousRoute = this.router.url;

            this.getAccounts().then(() => {
                if (this.accountsAvailable) {
                    if (update.selectedAddress && previousAccountAddress) {
                        if (update.selectedAddress.toString().toLocaleLowerCase()
                            !== previousAccountAddress.toString().toLocaleLowerCase()) {
                            this.router.navigateByUrl('/').then(() => {
                                return this.router.navigateByUrl(previousRoute);
                            });
                        } else {
                            // console.log('no change', update.selectedAddress, previousAccountAddress);
                        }
                    } else {
                        if (!update.selectedAddress && previousAccountAddress) {
                            this.web3.currentProvider.publicConfigStore.on('update', (newUpdate) => {
                                update.selectedAddress = this.accountsList[0];
                                this.getAccounts().then(() => {
                                });
                            });
                        }
                    }
                } else {
                    if (previousAccountAddress) {
                        console.log('You are deconnected from metamask');
                        if (this.redirectCount === 0) {
                            return this.router.navigateByUrl('/').then(() => this.redirectCount++);
                        }
                    } else {
                        console.log('No account detected, yet?', update.selectedAddress);
                    }
                }
            });
        });
    }


    public enableMetamask() {
        window.addEventListener('load', async () => {
            // Modern dapp browsers...
            if ((<any>window).ethereum) {
                (<any>window).web3 = new Web3((<any>window).ethereum);
                try {
                    // Request account access if needed
                    await (<any>window).ethereum.enable();
                    // Acccounts now exposed
                    console.log('enabled');
                } catch (error) {
                    // User denied account access...
                }
            }
            // Legacy dapp browsers...
            else if ((<any>window).web3) {
                (<any>window).web3 = new Web3(this.web3.currentProvider);
                // Acccounts always exposed
                console.log('enabled');
            }
            // Non-dapp browsers...
            else {
                console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
            }
        });
    }


    public hasBrowserMetaMask() {
        //return (this.web3.currentProvider.isMetaMask === true) && (typeof this.web3 !== 'undefined');
        return (typeof this.web3 !== 'undefined');
    }

    public currentAccountListener() {
        setInterval(() => {
            this.zone.run(() => {
                this.currentAccount();
            });
        }, 1000);
    }

    public currentAccount() {
        if (this.accountsList.length > 0) {
            this.currentAccountAddress = this.accountsList[0];
            return this.currentAccountAddress;
        }
    }

    /** Get the current account */

    public getBalance(account: string): Promise<number> {
        if (account) {
            return this.web3.eth.getBalance(account);
        }
    }

    public getNumberOfOwnedYummiesByAccountAndYummyId(account: string, yummyId: number) {
        return this._tokenContract.methods.numOwnedRedeemable(account, yummyId).call()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public buyYummy(account, yummyId: number, price, isSwiss) {
        return this._tokenContract.methods.buyRedeemable(yummyId)
            .send({
                from: account,
                value: this.web3.utils.toWei(price.toString(), 'ether')
            })
            .on('transactionHash', (hash) => {
                this.transactionHash = hash;
                this.etherScanLink = environment.etherscanLink + hash;
                this.transactionAddress.next(this.transactionHash);
                /* alert('Your transaction is being validated please check it at this address ' +
                     'https://etherscan.io/tx/' + hash);*/
                /* if (window.confirm('You transaction is being validated you can check it at this address' +
                         ' https://etherscan.io/tx/' + hash)) {
                     window.open('https://etherscan.io/tx/' + hash, '_blank');
                 }*/
                this.googleAnalyticsEvent(hash, isSwiss);
            })
            .then((res) => {
                document.getElementById('closetxModal').click();
                this.transactionAddress.next(false);
                // this.router.navigateByUrl('/').then(() => {
                this.router.navigateByUrl('/myyummieshatching').then(() => {
                });
                // });
                this.transactionHash = '';
                return res;
            })
            .catch((err) => {
                this.transactionHash = '';
                console.log(err);
            });
    }

    public getYummyPrice(yummyId) {
        return this._tokenContract.methods.redeemPrice(yummyId).call().then((price) => {
            return this.web3.utils.fromWei(price, 'ether');
        });
    }

    protected googleAnalyticsEvent(hash, isSwiss) {
        (<any>window).gtag('config', 'UA-123146504-1', {
            'custom_map': {
                'dimension1': 'is_swiss',
                'dimension2': 'transaction_hash'
            }
        });
        (<any>window).gtag('event', 'buying_yummy', {
            'is_swiss': isSwiss,
            'transaction_hash': hash
        });
    }
}
