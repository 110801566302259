import { NgModule, ModuleWithProviders, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

// Web3
import {WEB3} from './tokens';
const Web3 = require('web3');

// Services
import { EthService } from './eth.service';

export function getProvider() {
    return new Web3(Web3.givenProvider || 'ws://localhost:8546');
}

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        EthService, {
            provide: WEB3,
            useFactory: getProvider,
        }]
})
export class EthereumModule { }
